import { US_ID, CANADIAN_ID } from '../constants/personal-info';

export const getPrimarySpecialtyFromExpertise = expertise => {
  let primary = expertise.specialties.find(entry => entry.isPrimary);
  if (primary === undefined && expertise.specialties.length) {
    primary = expertise.specialties[0];
  }
  return primary === undefined ? null : primary;
};

const uSStates = states =>
  states.filter(s => s.countryId === US_ID || s.countryId === null);
const canadianStates = states =>
  states.filter(s => s.countryId === CANADIAN_ID || s.countryId === null);

export const getCountryIdByStateItem = (state, stateOptions) =>
  stateOptions.find(s => s.value === state).countryId;

export const stateOptionsByCountryId = (stateOptions, countryId) =>
  String(countryId) === String(CANADIAN_ID)
    ? canadianStates(stateOptions)
    : uSStates(stateOptions);

export const hasMailAddress = data => {
  return (
    data.mailStateId ||
    data.mailStreetAddress ||
    data.mailStreetAddress2 ||
    data.mailCity ||
    data.mailZipCode
  );
};
