import React from 'react';
import { Footer, IuiThemeProvider, CreateTheme, Linker } from '@ingenovis/iui';
import { createTheme, ThemeProvider as MuiThemeProvider } from '@mui/material';
import { ThemeProvider } from '@emotion/react';

import sitemap from './sitemap';
import LinkWrapper from '../nurse-portal-link-wrapper';

export default () => {
  const iuiTheme = CreateTheme({
    brand: 'trustaff',
  });
  const muiTheme = createTheme();
  const theme = { ...muiTheme, ...iuiTheme };

  return (
    <Linker CustomLink={LinkWrapper}>
      <MuiThemeProvider theme={theme}>
        <ThemeProvider theme={theme}>
          <IuiThemeProvider theme={theme}>
            <Footer
              sitemap={sitemap}
              company="Trustaff"
              seal="Trustaff Travel Nursing is certified by The Joint Comission and has earned its Gold Seal of Approval"
              termsURL="/terms-of-use/"
              privacyURL="/privacy-policy/"
              facebookURL="https://www.facebook.com/trustaffHealthcareCareers/"
              linkedinURL="https://www.linkedin.com/company/trustaff/"
              twitterURL="https://twitter.com/trustaff"
              instagramURL="https://www.instagram.com/trustafftravel/"
              contactNumber="(877) 880-0346"
              contactHours="Monday – Friday, 8am to 6pm EST"
              currentYear="2022"
              sealUrl="/joint-commission/"
            />
          </IuiThemeProvider>
        </ThemeProvider>
      </MuiThemeProvider>
    </Linker>
  );
};
