import React from 'react';
import { Link } from 'gatsby';

import { nursePortalPagesReleased } from '../../constants/nurse-portal-pages-released';

const LinkWrapper = ({ children, ...props }) => {
  if (nursePortalPagesReleased.includes(props.href)) {
    return <a {...props}>{children}</a>;
  }
  return <Link {...props}>{children}</Link>;
};

export default LinkWrapper;
