export default {
  globalNav: [
    {
      label: 'For Nurses',
      route: '/nurses/faq/',
      type: 'internal',
      subsections: [
        {
          label: 'See All Jobs',
          route: 'https://www.trustaff.com/jobs',
        },
        {
          label: 'How It Works',
          route: '/nurses/how-it-works/',
          type: 'internal',
        },
        {
          label: 'Salary & Benefits',
          route: '/nurses/salary-benefits/',
          type: 'internal',
        },
        {
          label: 'ACT Program',
          route: '/ACTprogram/',
          type: 'internal',
        },
        {
          label: 'Perks & Discounts',
          route: '/perks-discounts/',
          type: 'internal',
        },
        {
          label: 'Mental Health Support',
          route: '/mental-health/',
          type: 'internal',
        },
        {
          label: 'Referrals',
          route: '/referrals/',
          type: 'internal',
        },
        {
          label: 'FAQ',
          route: '/nurses/faq/',
          type: 'internal',
        },
      ],
    },
    {
      label: 'For Allied',
      route: '/allied-health/how-it-works/',
      type: 'internal',
      subsections: [
        {
          label: 'See All Jobs',
          route: 'https://www.trustaff.com/jobs',
        },
        {
          label: 'How It Works',
          route: '/allied-health/how-it-works/',
          type: 'internal',
        },
        {
          label: 'Salary & Benefits',
          route: '/allied-health/salary-benefits/',
          type: 'internal',
        },
        {
          label: 'ACT Program',
          route: '/ACTprogram/',
          type: 'internal',
        },
        {
          label: 'Perks & Discounts',
          route: '/perks-discounts/',
          type: 'internal',
        },
        {
          label: 'Mental Health Support',
          route: '/mental-health/',
          type: 'internal',
        },
        {
          label: 'Referrals',
          route: '/referrals/',
          type: 'internal',
        },
        {
          label: 'FAQ',
          route: '/allied-health/faq/',
          type: 'internal',
        },
      ],
    },
    {
      label: 'Staffing Solutions',
      route: '/staffing-solutions/Working-with-trustaff/',
      type: 'internal',
      subsections: [
        {
          label: 'Working with Trustaff',
          route: '/staffing-solutions/Working-with-trustaff/',
          type: 'internal',
        },
        {
          label: 'Staffing Request',
          route: '/staffing-solutions/request/',
          type: 'internal',
        },
      ],
    },
    {
      label: '#TrustaffTraveler',
      route: '/trustaff-traveler/',
      type: 'internal',
      subsections: [
        {
          label: "Traveler's Hub",
          route: '/trustaff-traveler/',
          type: 'internal',
        },
        {
          label: 'Perks & Discounts',
          route: '/perks-discounts/',
          type: 'internal',
        },
      ],
    },
    {
      label: 'COVID-19',
      route: '/covid19/crisis-jobs/',
      type: 'internal',
      subsections: [
        {
          label: 'Crisis Jobs',
          route: '/covid19/crisis-jobs',
          type: 'internal',
        },
        {
          label: 'COVID-19 Resource Center',
          route: '/covid19/',
          type: 'internal',
        },
      ],
    },
  ],
  userNav: [
    {
      label: 'My profile',
      route: '/profile/',
      type: 'internal',
    },
    {
      label: 'Resources',
      route: '/profile/resources/',
      type: 'internal',
    },
    {
      label: 'Documents',
      route: '/profile/documents/',
      type: 'internal',
    },
    {
      label: 'Log out',
      route: '/log-out/',
      type: 'internal',
    },
  ],
  signIn: {
    route: 'https://www.trustaff.com/api/auth/login',
  },
  applyNow: {
    route: 'https://www.trustaff.com/apply',
  },
};
