// Pages that are redirected to the Nurse Portal app via the Azure CDN should not be prefetched
export const nursePortalPagesReleased = [
  '/',
  '/jobs/',
  '/apply/',
  '/privacy-policy/',
  '/terms-of-use/',
  '/nurses/faq/',
  '/allied/faq/',
  '/about-us/',
  '/nurses/salary-benefits/',
  '/allied-health/salary-benefits/',
  '/perks-discounts/',
  '/staffing-solutions/request/',
  '/contact-us/',
  '/trustaff-traveler/',
  '/trustaff-traveler/photo-contest/',
  '/corporate-careers/',
  '/mental-health/',
  '/staffing-solutions/Working-with-trustaff/',
  '/referrals/',
  '/nurses/how-it-works/',
  '/allied-health/how-it-works/',
  '/blog/',
  '/covid19/',
  '/covid19/crisis-jobs/',
  '/ACTprogram/',
  '/api/auth/login',
];
