export const US_ID = 1;
export const CANADIAN_ID = 223;

export const COUNTRY_OPTIONS = [
  { value: US_ID, name: 'United States' },
  { value: CANADIAN_ID, name: 'Canada' },
];

export const SUFFIX_OPTIONS = [
  { value: 'None', name: 'None' },
  { value: 'Jr', name: 'Jr' },
  { value: 'Sr', name: 'Sr' },
  { value: 'III', name: 'III' },
  { value: 'IV', name: 'IV' },
  { value: 'V', name: 'V' },
];
