import React, { useState, useEffect } from 'react';
import Helmet from 'react-helmet';

import Header from './header';
import Footer from './footer/footer';
import ContactBanner from './contact-banner';

import referrerDataService from '../services/referrer-data-service';
import { isIE11 } from '../utils/browser-detection-helpers';
import styles from '../styles/styles.scss'; // eslint-disable-line no-unused-vars
import PropTypes from 'prop-types';

const Layout = ({
  children,
  headerComponent: HeaderComponent,
  footerComponent: FooterComponent,
  role,
  hideContactBanner,
  showHeader,
  showFooter,
}) => {
  const [showIEClass, setShowIEClass] = useState(false);

  useEffect(() => {
    // on component mount check if browser is IE11 and add class
    // added in useEffect block so it React doesn't attempt to run during SSR
    setShowIEClass(isIE11());

    referrerDataService.saveUtmParamsIfMissing();
    referrerDataService.saveReferrerIfMissing();
  }, []);

  return (
    <>
      <Helmet>
        <link
          href="https://fonts.googleapis.com/css?family=Nunito+Sans:400,600,700&display=swap"
          rel="stylesheet"
        />
        <link
          href="https://fonts.googleapis.com/css?family=Open+Sans:400,400i,600,700&display=swap"
          rel="stylesheet"
        />
        <link rel="icon" type="image/x-icon" href="/gatsby/favicon.ico"></link>
      </Helmet>
      {!hideContactBanner && <ContactBanner />}
      {showHeader && HeaderComponent && <HeaderComponent role={role} />}
      <main className={showIEClass ? 'is-ie11' : 'is-not-ie11'}>
        {children}
      </main>
      {showFooter && FooterComponent && <FooterComponent />}
    </>
  );
};

export default Layout;

Layout.propTypes = {
  headerComponent: PropTypes.oneOfType([PropTypes.object, PropTypes.func]),
  footerComponent: PropTypes.oneOfType([PropTypes.object, PropTypes.func]),
  hideContactBanner: PropTypes.bool,
};

Layout.defaultProps = {
  headerComponent: Header,
  footerComponent: Footer,
  hideContactBanner: false,
  showHeader: true,
  showFooter: true,
};
