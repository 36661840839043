import React from 'react';

import {
  IuiThemeProvider,
  CreateTheme,
  Linker,
  ContactBanner,
} from '@ingenovis/iui';
import { createTheme, ThemeProvider as MuiThemeProvider } from '@mui/material';
import { Link } from 'gatsby';
import { ThemeProvider } from '@emotion/react';

export default () => {
  const iuiTheme = CreateTheme({
    brand: 'trustaff',
  });
  const muiTheme = createTheme();
  const theme = { ...muiTheme, ...iuiTheme };

  return (
    <Linker CustomLink={Link}>
      <MuiThemeProvider theme={theme}>
        <ThemeProvider theme={theme}>
          <IuiThemeProvider theme={theme}>
            <ContactBanner
              phoneNumber="(877) 880-0346"
              timings="Monday – Friday, 8am to 6pm EST"
            />
          </IuiThemeProvider>
        </ThemeProvider>
      </MuiThemeProvider>
    </Linker>
  );
};
