import React, { useEffect, useState } from 'react';
import { Navbar, IuiThemeProvider, CreateTheme, Linker } from '@ingenovis/iui';
import { createTheme, ThemeProvider as MuiThemeProvider } from '@mui/material';
import { ThemeProvider } from '@emotion/react';
import { useLocation } from '@reach/router';
import { useAuth } from '../../providers/auth-provider';
import { authRoles } from '../../constants/auth-roles';
import { useAuthenticatedRequest } from '../../hooks/api-hooks';
import { navigate } from 'gatsby';
import {
  getProfileApiUrl,
  getSpecialtiesApiUrl,
} from '../../utils/url-helpers';
import { getPrimarySpecialtyFromExpertise } from '../../utils/profile-utils';
import sitemap from './sitemap';
import LinkWrapper from '../nurse-portal-link-wrapper';

export default () => {
  const { get } = useAuthenticatedRequest();
  const { getProfileId, loggedIn, getFullName, hasRole } = useAuth();

  const currentLocation = useLocation();
  const profileId = getProfileId();
  const fullName = getFullName();

  const [userTitle, setUserTitle] = useState();
  const [userDepartment, setUserDepartment] = useState();

  const iuiTheme = CreateTheme({
    brand: 'trustaff',
  });
  const muiTheme = createTheme();
  const theme = { ...muiTheme, ...iuiTheme };

  useEffect(() => {
    if (!profileId || !hasRole(authRoles.APPLICANT)) return;

    const getUserTitleAndDepartment = async () => {
      const expertise = await getExpertise(profileId);

      const professionName = expertise?.profession?.name;
      setUserTitle(professionName ?? '');

      const primarySpecialty = getPrimarySpecialtyFromExpertise(expertise);
      if (!primarySpecialty) return;

      const primarySpecialtyName = await getSpecialtyName(
        primarySpecialty.specialtyId
      );
      setUserDepartment(primarySpecialtyName ?? '');
    };

    getUserTitleAndDepartment();
  }, [profileId]);

  const getExpertise = async profileId => {
    const expertise = await get(getProfileApiUrl(profileId, 'expertise'));
    return expertise.data;
  };

  const getSpecialtyName = async specialtyId => {
    const specialty = await get(getSpecialtiesApiUrl(specialtyId));
    return specialty?.data?.name;
  };

  const getUsername = () => {
    if (!loggedIn) return undefined;
    return `${fullName.firstName} ${fullName.lastName}`;
  };

  const getActiveRoute = () => {
    let path = currentLocation.pathname;

    if (!path.endsWith('/')) path += '/';

    return path;
  };

  return (
    <Linker CustomLink={LinkWrapper}>
      <MuiThemeProvider theme={theme}>
        <ThemeProvider theme={theme}>
          <IuiThemeProvider theme={theme}>
            <Navbar
              sitemap={sitemap.globalNav}
              secondarySitemap={[]}
              userSitemap={sitemap.userNav}
              mobileUserSitemap={sitemap.userNav}
              signInRoute={sitemap.signIn}
              applyNowRoute={sitemap.applyNow}
              activeRoute={getActiveRoute()}
              userTitle={userTitle}
              userDepartment={userDepartment}
              username={getUsername()}
              onApplyNowClick={() => navigate(sitemap.applyNow.route)}
              showSearchIcon={false}
            />
          </IuiThemeProvider>
        </ThemeProvider>
      </MuiThemeProvider>
    </Linker>
  );
};
