export function GatsbyImg(imgName, data) {
  this.ogImgUrl = getOgImgUrl(imgName, data);
  this.fluidImg = getFluidImgObj(imgName, data);
}

function getOgImgUrl(imgName, data) {
  const imgPath = getFluidImgObj(imgName, data).src;
  const ogImgUrl = `https://www.trustaff.com${imgPath}`;

  return ogImgUrl;
}

function getFluidImgObj(imgName, data) {
  const imgObjFluid = getImgEdge(imgName, data).node.childImageSharp.fluid;
  return imgObjFluid;
}

function getImgEdge(imgName, data) {
  return data.allFile.edges.find(e => e.node.name === imgName);
}
