export default [
  { label: 'About Us', link: '/about-us/' },
  { label: 'FAQ', link: '/nurses/faq/' },
  { label: 'Referrals', link: '/referrals/' },
  { label: 'Contact Us', link: '/contact-us/' },
  { label: 'Blog', link: '/blog/' },
  {
    label: 'Trustaff Careers',
    link: '/corporate-careers/',
    type: 'internal',
  },
  {
    label: 'Ingenovis Health',
    link: 'http://ingenovishealth.com/',
    type: 'external',
  },
];
