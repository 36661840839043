export const isIE11 = () => {
  return !!window.MSInputMethodContext && !!document.documentMode;
};

export const isEdge = () => {
  return window.navigator.userAgent.indexOf('Edge') > -1;
};

export const isMobile = () => {
  let userAgent = navigator.userAgent || navigator.vendor || window.opera;

  return (
    !window.MSStream &&
    /windows phone|android|iPad|iPhone|iPod/i.test(userAgent)
  );
};
