import React from 'react';
import { Link } from 'gatsby';
import Img from 'gatsby-image';
import styles from './oh-referral.module.scss';

export default ({ fluid }) => {
  return (
    <div className="columns is-centered">
      <div className={`${styles.container}`}>
        <div className={`${styles.contentbox}`}>
          <Link to="/referrals/">
            <Img
              fluid={fluid}
              className={styles.aboutImageWrapper}
              alt="Ohio referral bonus special"
            />
          </Link>
          <h1 className={styles.header}>
            For a limited time, Ohio referral bonuses are
          </h1>
          <h2 className={styles.gigantor}>$750!</h2>
          <p className={`content`}>
            There are so many great job openings in Ohio right now that nurses
            are in <b>very high demand</b>. When you refer friends or colleagues
            who take a new Ohio assignment with Trustaff, you'll be rewarded
            with an{' '}
            <Link to="/referrals/">
              <b>increased referral bonus</b>
            </Link>{' '}
            for sending them our way!
            <br />
            <br />
            There’s no limit to the number of referrals you can complete, so
            it's a great way to pad your piggy bank!
          </p>
          <Link to="/referrals/" className="button is-primary">
            Refer someone
          </Link>
          <div className={`section ${styles.icons}`}>
            <p className={`content ${styles.restrictions}`}>
              *Valid for Ohio RN referrals submitted after 2/20/2020. All
              referral bonuses are paid within 30 days of the referred employee
              completing their assignment. Restrictions may apply, ask your
              recruiter for complete details.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};
